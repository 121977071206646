import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DialogModal = ({
  id,
  title,
  description,
  descriptionWithHide,
  onAgree,
  onDisagree,
  toggle,
  agreeLabel,
  disagreeLabel,
  withAgree,
  withDisagree,
  fullWidth,
  maxWidth,
  contentAlign,
  withAgreeHide,
}) => {
  const [isShown, setIsShown] = useState(false);
  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);
  const handleAgree = () => {
    hide();
    onAgree();
  };

  const handleAgreeWithHide = () => {
    onAgree(hide);
  };

  const handleDisagree = () => {
    hide();
    onDisagree();
  };

  return (
    <Fragment>
      {toggle(show)}
      <Dialog
        open={isShown}
        onClose={handleDisagree}
        aria-labelledby={`${id}-dialog-title`}
        aria-describedby={`${id}-dialog-description`}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        {title && <DialogTitle id={`${id}-dialog-title`}>{title}</DialogTitle>}
        {description && (
          <DialogContent>
            <DialogContentText id={`${id}-dialog-description`} component="div" sx={{ textAlign: contentAlign }}>
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        {descriptionWithHide && (
          <div id={`${id}-dialog-description`} sx={{ textAlign: contentAlign }}>
            {descriptionWithHide(hide)}
          </div>
        )}
        <DialogActions>
          {withDisagree && (
            <Button onClick={() => handleDisagree()} color="primary">
              {disagreeLabel}
            </Button>
          )}
          {withAgree && (
            <Button onClick={() => handleAgree()} color="secondary" autoFocus>
              {agreeLabel}
            </Button>
          )}
          {withAgreeHide && (
            <Button onClick={() => handleAgreeWithHide()} color="secondary" autoFocus>
              {agreeLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DialogModal.defaultProps = {
  agreeLabel: 'Agree',
  disagreeLabel: 'Disagree',
  onAgree: () => { },
  onDisagree: () => { },
  withAgree: false,
  withAgreeHide: false,
  withDisagree: false,
  fullWidth: false,
  maxWidth: 'sm',
  contentAlign: 'left',
  description: false,
  descriptionWithHide: false,
};

DialogModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  agreeLabel: PropTypes.string,
  disagreeLabel: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  withAgree: PropTypes.bool,
  withAgreeHide: PropTypes.bool,
  withDisagree: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  descriptionWithHide: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  contentAlign: PropTypes.string,
};

export default DialogModal;